<template>
  <div @dragover.prevent @drop.prevent>
    <section class="section sb-section">
      <div class="sb-section--title-bar">
        <h2 class="title is-4">Upload Files</h2>
      </div>
      <div class="sb-section--content">
        <button
          class="button button--done is-success"
          @click="onDone"
          :disabled="!isCompleted">
          <span class="icon is-small">
            <i class="fa fa-eye-slash"></i>
          </span>
          <span>Blind Files</span>
        </button>
        <file-uploader
          ref="uploader"
          :project="project"
          v-on:files-added="onFilesAdded"
          v-on:upload-complete="onUploadComplete"
        ></file-uploader>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import FileUploader from './file_project_uploader'
import { FILE_PROJECT_ENDPOINT } from '../consts'

export default {
  components: { FileUploader },
  props: {
    initialProject: {
      name: '',
      is_filename_public: true,
      created_at: ''
    }
  },
  data: function () {
    return {
      project: this.initialProject,
      isCompleted: false
    }
  },
  methods: {
    onDone() {
      window.location.assign(`${FILE_PROJECT_ENDPOINT}/${this.project.id}`)
    },
    onFilesAdded () {
      this.isCompleted = false
    },
    onUploadComplete() {
      this.isCompleted = true
    }
  }
}
</script>

<style scoped>
  .tile {
    text-align: center;
  }
  .tile > div {
    width: 100%;
  }
  .tile > div img {
    margin: 0 auto 10px auto;
  }
  .button--done {
    position: absolute;
    left: 300px;
  }
</style>
