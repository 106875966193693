<template>
  <div class="modal sb-modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">New Card</p>
      </header>
      <section class="modal-card-body">
        <form v-on:submit="onModalSave">
          <div class="field">
            <label class="label">Enter a name for your new card.</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Name"
                ref="name"
                v-model="card.name"
                >
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click="onModalSave" :disabled="isSaving">Save</button>
        <button class="button is-secondary" @click="onModalCancel" :disabled="isSaving">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { STUDY_ENDPOINT } from '../consts.js'
import SbError from '../SbError'

const MODULE_ID = "CardNewModal"

export default {
  props: {
    studyId: Number,
    visible: false
  },
  data: function() {
    return {
      isSaving: false,
      card: {
        name: ''
      }
    }
  },
  methods: {
    getNewCardEndpoint(studyId) {
      return `${STUDY_ENDPOINT}/${studyId}/cards`
    },
    saveNewCard() {
      this.isSaving = true
      return axios
        .post(this.getNewCardEndpoint(this.studyId), this.card)
        .then(async result => {
          this.card = result.data
          this.$emit('card-create-success', this.card)
        })
        .catch(error => {
          this.$emit('card-create-error')
          new SbError(SbError.REQUEST_FAILED, error, MODULE_ID, 'saveNewCard')
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    onModalSave(event) {
      event.preventDefault()
      this.isSaving = true
      this.saveNewCard()
    },
    onModalCancel() {
      this.$emit('card-create-cancel')
    }
  }
}
</script>

<style scoped>
</style>
