<template>
  <div class="modal sb-modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">New Study</p>
      </header>
      <section class="modal-card-body">
        <form v-on:submit="onModalSave">
          <div class="field">
            <label class="label">Enter a name for your new study.</label>
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Name"
                ref="name"
                v-model="study.name"
                >
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click="onModalSave" :disabled="isSaving">Save</button>
        <button class="button is-secondary" @click="onModalCancel" :disabled="isSaving">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { STUDY_ENDPOINT } from '../consts.js'
import SbError from '../SbError'

const MODULE_ID = "StudyProjectNewModal"

export default {
  props: {
    visible: false
  },
  data: function() {
    return {
      isSaving: false,
      study: {
        name: ''
      }
    }
  },
  methods: {
    saveNewStudy() {
      this.isSaving = true
      return axios
        .post(STUDY_ENDPOINT, this.study)
        .then(async result => {
          this.study = result.data
          this.$emit('study-create-success', this.study)
        })
        .catch(error => {
          this.$emit('study-create-error')
          new SbError(SbError.REQUEST_FAILED, error, MODULE_ID, 'saveNewStudy')
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    onModalSave(event) {
      event.preventDefault()
      this.isSaving = true
      this.saveNewStudy()
    },
    onModalCancel() {
      this.$emit('study-create-cancel')
    }
  }
}
</script>

<style scoped>
</style>
