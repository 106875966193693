<template>
  <div @dragover.prevent @drop.prevent>
    <error-modal
      v-if="error"
      :error="error"
      v-on:error-modal-close="error = null"
    >
    </error-modal>
    <section class="section sb-section">
      <div class="sb-section--title-bar">
        <h2 class="title is-4">Edit File Project Settings</h2>
      </div>
      <div class="sb-section--content">
        <div v-show="notificationName.text" class="notification" :class="notificationName">{{ notificationName.text }}</div>
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="project.name" :disabled="isSaving">
          </div>
        </div>
        <div class="field">
          <div class="control">
            <div class="checkbox-container">
            <input id="checkbox" type="checkbox" v-model="project.is_filename_public" :disabled="isSaving" name="" value="">
            <label for="checkbox"><span>Show original file names?</span></label>
          </div>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <button
              class="button is-primary"
              @click="onSaveProjectSettings"
              :class="{ 'is-loading': isSaving }"
              :disabled="isSaving">Save</button>
          </div>
        </div>
      </div>
    </section>
    <section
      class="section sb-section"  >
      <div class="sb-section--title-bar">
        <h2 class="title is-4">Attached Files</h2>
      </div>
      <div class="sb-section--content">
        <div class="attachments" v-if="attachments.length">
          <div v-for="attachment in attachments" :key="attachment.id" class="attachment" :class="{ deleting: attachment.deleting }">
            <div class="image-wrap">
              <div :style='{ backgroundImage: `url("${attachment.file.url}")` }'></div>
            </div>
            <p class="image-title">
              {{ showPublicFilenameAttachments ? attachment.name : attachment.blind_name }}
            </p>
            <button
              v-if="editFilesEnabled"
              class="button is-danger"
              @click="onDeleteAttachment(attachment)"
              :disabled="isDeleting">Delete</button>
          </div>
          <div class="attachment attachment-grow"></div>
        </div>
        <p v-else><em>No attachments</em></p>
      </div>
    </section>
    <section
      v-if="editFilesEnabled"
      class="section sb-section">
      <div class="sb-section--title-bar">
        <h2 class="title is-4">Add Files</h2>
      </div>
      <div class="sb-section--content">
        <file-uploader
          ref="uploader"
          :project="project"
          v-on:upload-complete="onUploadComplete"
        ></file-uploader>
      </div>
    </section>
    <section class="section sb-section sb-section--warning">
      <div class="sb-section--title-bar">
        <h2 class="title is-4">Delete Project</h2>
      </div>
      <div class="sb-section--content">
        <p>Deleting your Project and its attachments is irreversible.</p>
        <button class="button is-danger" @click="onDelete">Delete Project</button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import ErrorModal from './error_modal'
import FileUploader from './file_project_uploader'
import { FILE_PROJECT_ENDPOINT } from '../consts'
import SbError from '../SbError'

const MODULE_ID = "FileProjectEditor"

export default {
  components: { FileUploader, ErrorModal },
  props: {
    initialProject: {
      name: '',
      created_at: ''
    },
    initialEditFiles: Boolean
  },
  data: function () {
    return {
      project: this.initialProject,
      editFilesEnabled: this.initialEditFiles,
      attachments: [],
      isSaving: false,
      isDeleting: false,
      notificationName: {
        'is-danger': false,
        'is-success': false,
        text: ''
      },
      error: null,
      showPublicFilenameAttachments: this.initialProject.is_filename_public
    }
  },
  created: function () {
    this.loadAttachments()
  },
  methods: {
    onSaveProjectSettings() {
      this.isSaving = true
      return axios
        .put(`${FILE_PROJECT_ENDPOINT}/${this.project.id}`, this.project)
        .then(async result => {
          this.project = result.data
          this.showPublicFilenameAttachments = this.project.is_filename_public
          this.notificationName['is-danger'] = false
          this.notificationName['is-success'] = true
          this.notificationName.text = "File Project settings have been updated."
        })
        .catch(error => {
          this.notificationName['is-success'] = false
          this.notificationName['is-danger'] = true
          this.notificationName.text = "An error occured while trying to save your File Project settings. Please try again."
          new SbError(SbError.REQUEST_FAILED, 'Error saving File Project settings', MODULE_ID, 'onSaveProjectSettings')
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    loadAttachments() {
      return axios
        .get(`${FILE_PROJECT_ENDPOINT}/${this.project.id}/attachments`)
        .then(async result => {
          this.attachments = result.data
        })
        .catch(error => {
          this.error = new SbError(SbError.REQUEST_FAILED, 'Error loading files', MODULE_ID, 'loadAttachments')
        })
    },
    deleteAttachment(attachment) {
      this.isDeleting = true
      let original = this.attachments.slice()
      let without = this.attachments.filter(item => item.id !== attachment.id)
      return axios
        .delete(`${FILE_PROJECT_ENDPOINT}/${this.project.id}/detach/${attachment.id}`)
        .then(() => {
          this.attachments = without
        })
        .catch(error => {
          this.error = new SbError(SbError.REQUEST_FAILED, 'Error deleting file', MODULE_ID, 'deleteAttachment')
        })
        .finally(() => {
          this.isDeleting = false
        })
    },
    deleteProject() {
      this.isSaving = true
      return axios
        .delete(`${FILE_PROJECT_ENDPOINT}/${this.project.id}`)
        .then(async result => {
          window.location.href = `${FILE_PROJECT_ENDPOINT}`;
        })
        .catch(error => {
          this.error = new SbError(SbError.REQUEST_FAILED, 'Error deleting File Project', MODULE_ID, 'deleteProject')
        })
    },
    onUploadComplete() {
      this.isSaving = false
      this.loadAttachments().then(() => {
        this.$refs.uploader.removeAllFiles()
      })
    },
    onDeleteAttachment(attachment) {
      this.deleteAttachment(attachment)
    },
    onDelete() {
      if (window.confirm("Do you really want to delete this File Project?")) {
        this.deleteProject()
      }
    }
  }
}
</script>

<style scoped>
  .attachments {
    display: flex;
    max-height: 440px;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
  }
  .attachment {
    flex-basis: 168px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 40px;
    text-align: center;
  }
  .attachment-grow {
    flex-grow: 1;
  }
  .attachment .deleting {
    opacity: 0.25;
  }
  .attachment .image-wrap {
    margin: auto;
    max-width: 128px;
    text-align: center;
    background-color: #7f8896;
    background-image: url('../../assets/images/file-image-solid.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 32px 32px;
  }
  .attachment .image-wrap > div {
    width: 128px;
    height:128px;
    margin: 0 auto 10px auto;
    background-size: cover;
  }
  .attachment .image-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: no-wrap;
  }
</style>
