<template>
  <div class="file-study">
    <error-modal
      v-if="error"
      :error="error"
      v-on:error-modal-close="error = null"
    >
    </error-modal>
    <study-new-modal
      v-if="isAddNewState"
      v-on:study-create-success="onStudyCreateSuccess"
      v-on:study-create-error="onStudyCreateError"
      v-on:study-create-cancel="onModalCancel"
    >
    </study-new-modal>
    <section class="sb-section hero">
      <div class="level">
        <div class="level-left">
          <div>
            <h1 class="title sb-title">Studies</h1>
            <p>Select or create a new study to get started.</p>
          </div>
        </div>
        <div class="level-right">
          <button class="button is-secondary is-add is-pulled-right" @click="isAddNewState = true">
            <span class="icon is-small">
              <i class="fa fa-plus"></i>
            </span>
            New Study
          </button>
        </div>
      </div>
    </section>
    <div class="container">
      <div v-if="!studies || !studies.length">
        <button class="button is-primary" @click="isAddNewState = true">Create a New Study</button>
      </div>
      <table v-else class="table is-fullwidth is-striped sb-table sb-table--listing">
        <thead>
          <tr>
            <th><a href="#">Study Name</a></th>
            <th>Date Created</th>
            <th></th>
          </tr>
        </thead>
        <tfoot v-if="totalPages > 1">
          <tr>
            <th colspan="3">
              <div class="sb-pagination">
                {{ currentPage }} of {{ totalPages }}
                <a href="#" @click="onPreviousPage" :class="{disabled: currentPage === 1}">&lt;</a>
                <span>|</span>
                <a href="#" @click="onNextPage" :class="{disabled: currentPage === totalPages}">&gt;</a>
              </div>
            </th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-for="study in studies" @click="onStudyClick(study)">
            <td>{{ study.name }}</td>
            <td>{{ new Date(study.created_at).toLocaleString() }}</td>
            <td>
              <div class="buttons is-pulled-right">
                <a :href="getStudyEndpoint(study)">View</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import StudyNewModal from './study_new_modal'
import ErrorModal from './error_modal'
import { STUDY_ENDPOINT } from '../consts.js'
import SbError from '../SbError'

const MODULE_ID = "Studies"

export default {
  components: { StudyNewModal, ErrorModal },
  props: {
    initialStudies: Array,
    initialAddNew: Boolean,
    initialTotalPages: Number,
    initialCurrentPage: Number
  },
  data: function() {
    return {
      isAddNewState: this.initialAddNew,
      studies: this.initialStudies,
      totalPages: this.initialTotalPages,
      currentPage: this.initialCurrentPage || 1,
      error: null
    }
  },
  methods: {
    getStudyEndpoint(study) {
      return `${STUDY_ENDPOINT}/${study.id}`
    },
    getStudyEditEndpoint(study) {
      return `${STUDY_ENDPOINT}/${study.id}/edit`
    },
    onStudyCreateSuccess(study) {
      window.location.assign(`${STUDY_ENDPOINT}/${study.id}`)
    },
    onStudyCreateError(error) {
      this.isAddNewState = false
      // error already logged by modal component
      this.error = new SbError(SbError.REQUEST_FAILED, 'Error creating new Study', MODULE_ID, 'onStudyCreateError', false)
    },
    onModalCancel() {
      this.isAddNewState = false
    },
    onNextPage() {
      if (this.currentPage !== this.totalPages) {
        window.location.assign(`${STUDY_ENDPOINT}?page=${this.currentPage + 1}`)
      }
    },
    onPreviousPage() {
      if (this.currentPage !== 1) {
        window.location.assign(`${STUDY_ENDPOINT}?page=${this.currentPage - 1}`)
      }
    },
    onStudyClick (study) {
      let url = this.getStudyEndpoint(study)
      window.location = url
    }
  }
}
</script>

<style scoped>
</style>
