<template>
  <div class="modal sb-modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Error</p>
        <button class="delete" aria-label="close" @click="onModalClose"></button>
      </header>
      <section class="modal-card-body">
        <p>{{ error.message }}</p>
        <p class="message-info">Please file a support ticket if the problem persists.</p>
        <div class="message">
          <div class="message-header">
            <p>Error Details</p>
          </div>
          <div class="message-body">
            {{ error }}
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click="onModalClose">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>

import SbError from '../SbError'

export default {
  props: {
    error: Error
  },
  methods: {
    onModalClose() {
      this.$emit('error-modal-close')
    }
  }
}

</script>

<style scoped>
  .message {
    margin: 20px 0 10px;
    background-color: rgba(28,44,78,0.25)
  }
  .message-header {
    color: white;
    background-color: rgba(28,44,78,0.35)
  }
  .message-info {
    margin: 10px 0;
    font-style: italic;
  }
</style>
