<template>
  <div>
    <error-modal
      v-if="error"
      :error="error"
      v-on:error-modal-close="error = null"
    >
    </error-modal>
    <section class="section sb-section">
      <div class="sb-section--title-bar">
        <h2 class="title is-4">Title</h2>
      </div>
      <div class="sb-section--content">
        <div v-show="notificationName.text" class="notification" :class="notificationName">{{ notificationName.text }}</div>
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="card.name" :disabled="isSaving">
          </div>
        </div>
        <div class="field">
          <div class="control">
            <button
              class="button is-primary"
              @click="onSaveCardSettings"
              :class="{ 'is-loading': isSaving }"
              :disabled="isSaving">Save</button>
          </div>
        </div>
      </div>
    </section>
    <section class="sb-section hero">
      <div class="level">
        <div class="level-left">
            <h2 class="title is-4">Label List ({{ totalCards }} Labels)</h2>
        </div>
        <div class="level-right">
          <button class="button is-secondary is-add is-pulled-right" @click="addNewLabel()">
            <span class="icon is-small">
              <i class="fa fa-plus"></i>
            </span>
            New Label
          </button>
        </div>
      </div>
    </section>
    <div v-show="notificationLabels.text" class="notification" :class="notificationLabels">{{ notificationLabels.text }}</div>
    <section class="section sb-section sb-section--labels">
      <div class="sb-section--title-bar columns">
        <div class="column is-3"><h2 class="title is-4">Criterion</h2></div>
        <div class="column is-5"><h2 class="title is-4">Value</h2></div>
        <div class="column  is-2"><h2 class="title is-4">Public/Blinded</h2></div>
        <div class="column  is-2"><h2 class="title is-4"></h2></div>
      </div>
      <div class="sb-section--content sb-section--list">
        <card-labeler
          ref="labeler"
          :card=card
          v-on:labels-save-success="onLabelSaveSuccess"
          v-on:labels-save-error="onLabelSaveError"
          v-on:labels-modified="onLabelModified"
        >
        </card-labeler>
      </div>
    </section>
    <section class="section sb-section">
      <div class="sb-section--title-bar">
        <h2 class="title is-4">Archive Card</h2>
      </div>
      <div v-if="!card.is_archived" class="sb-section--content">
        <p>Archiving your card will remove it from the study, but it will still be accessible for future needs.</p>
        <button class="button is-secondary" @click="this.onToggleArchivedCard">Archive Card</button>
      </div>
      <div v-else class="sb-section--content">
        <p>Unarchiving the card will return it to the study.</p>
        <button class="button is-secondary" @click="this.onToggleArchivedCard">Unarchive Card</button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import CardLabeler from './card_labeler'
import { CARD_ENDPOINT } from '../consts'
import ErrorModal from './error_modal'
import SbError from '../SbError'

const MODULE_ID = "CardEditor"

export default {
  components: { CardLabeler, ErrorModal },
  props: {
    initialAddNew: Boolean,
    initialCard: {
      id: '',
      name: '',
      label_data: '',
      created_at: ''
    }
  },
  data: function () {
    return {
      card: this.initialCard,
      isSaving: false,
      isDeleting: false,
      notificationName: {
        'is-danger': false,
        'is-success': false,
        text: ''
      },
      notificationLabels: {
        'is-danger': false,
        'is-success': false,
        text: ''
      },
      error: null
    }
  },
  computed: {
    cardEndpoint() {
      return `${CARD_ENDPOINT}/${this.card.id}`
    },
    totalCards () {
      if (this.$refs && this.$refs.labeler && this.$refs.labeler.getTotalCards()) {
        return this.$refs.labeler.getTotalCards()
      } else if (this.card.label_data && this.card.label_data.length) {
        return this.card.label_data.length
      } else {
        return 0
      }
    }
  },
  methods: {
    onSaveCardSettings() {
      this.isSaving = true
      return axios
        .put(this.cardEndpoint, {
          id: this.card.id,
          name: this.card.name
        })
        .then(async result => {
          this.card = result.data
          this.notificationName['is-danger'] = false
          this.notificationName['is-success'] = true
          this.notificationName.text = "Card settings have been updated."
        })
        .catch(error => {
          this.notificationName['is-success'] = false
          this.notificationName['is-danger'] = true
          this.notificationName.text = "An error occured while trying to save your Card settings. Please try again."
          new SbError(SbError.REQUEST_FAILED, 'Error saving Card settings', MODULE_ID, 'onSaveCardSettings')
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    onToggleArchivedCard() {
      this.isSaving = true
      return axios
        .put(`${this.cardEndpoint}/archive`, {
          id: this.card.id,
          is_archived: !this.card.is_archived
        })
        .then(async result => {
          this.card = result.data
          this.notificationName['is-success'] = true
          this.notificationName['is-danger'] = false
          window.location.reload(true)
        })
        .catch(error => {
          this.notificationName['is-success'] = false
          this.notificationName['is-danger'] = true
          this.notificationName.text = "An error occured while trying to archive your Card settings. Please try again."
          new SbError(SbError.REQUEST_FAILED, 'Error archiving card', MODULE_ID, 'onArchiveCard')
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    addNewLabel () {
      this.$refs.labeler.onAddLabel()
    },
    onLabelSaveSuccess() {
      this.notificationLabels['is-success'] = true
      this.notificationLabels['is-danger'] = false
      this.notificationLabels.text = "Your labels were updated."
    },
    onLabelSaveError() {
      this.notificationLabels['is-success'] = false
      this.notificationLabels['is-danger'] = true
      this.notificationLabels.text = "An error occured while trying to save your labels. Please try again."
    },
    onLabelModified () {
      this.notificationLabels.text = ""
    }
  }
}
</script>

<style scoped>
</style>
