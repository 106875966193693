/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue'
import FileProjects from '../components/file_projects'
import FileProjectEditor from '../components/file_project_editor'
import FileProjectUpload from '../components/file_project_upload'
import Studies from '../components/studies'
import StudyApp from '../components/study'
import CardEditor from '../components/card_editor'
import ProjectMenu from '../components/project_menu'
import axios from 'axios'
import { getCSRFToken } from '../utils/railsHelpers.js'

document.addEventListener('DOMContentLoaded', () => {

  axios.defaults.headers.common['X-CSRF-Token'] = getCSRFToken()
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  let file_projects = document.getElementById('file-projects-app')
  if (file_projects) {
    let projects = JSON.parse(file_projects.dataset.projects)
    let addNew = file_projects.dataset.addNew === 'true'
    let totalPages = parseInt(file_projects.dataset.totalPages, 10)
    let currentPage = parseInt(file_projects.dataset.currentPage, 10)
    if(addNew) {
      history.replaceState({}, '', '/files')
    }
    new Vue({
      el: file_projects,
      render: createElement => {
        return createElement(FileProjects,
          {
            props: {
              'initial-projects': projects,
              'initial-add-new': addNew,
              'initial-total-pages': totalPages,
              'initial-current-page': currentPage
            }
          }
        )
      }
    })
  }

  let file_editor = document.getElementById('file-project-editor-app')
  if (file_editor) {
    let project = JSON.parse(file_editor.dataset.project)
    let editFiles = file_editor.dataset.editfiles === 'true'
    const app = new Vue({
      el: file_editor,
      render: createElement => {
        return createElement(FileProjectEditor,
          {
            props: {
              'initial-project': project,
              'initial-edit-files': editFiles
            }
          }
        )
      }
    })
  }

  let file_project_upload = document.getElementById('file-project-upload-app')
  if (file_project_upload) {
    let project = JSON.parse(file_project_upload.dataset.project)
    const app = new Vue({
      el: file_project_upload,
      render: createElement => {
        return createElement(FileProjectUpload,
          {
            props: {
              'initial-project': project
            }
          }
        )
      }
    })
  }

  let all_studies = document.getElementById('studies-app')
  if (all_studies) {
    let studies = JSON.parse(all_studies.dataset.studies)
    let addNew = all_studies.dataset.addNew === 'true'
    let totalPages = parseInt(all_studies.dataset.totalPages, 10)
    let currentPage = parseInt(all_studies.dataset.currentPage, 10)
    if(addNew) {
      history.replaceState({}, '', '/studies')
    }
    new Vue({
      el: all_studies,
      render: createElement => {
        return createElement(Studies,
          {
            props: {
              'initial-studies': studies,
              'initial-add-new': addNew,
              'initial-total-pages': totalPages,
              'initial-current-page': currentPage
            }
          }
        )
      }
    })
  }

  let study_app = document.getElementById('study-app')
  if (study_app) {
    let study = JSON.parse(study_app.dataset.study)
    let cards = JSON.parse(study_app.dataset.cards)
    let addNew = study_app.dataset.addNew === 'true'
    let totalPages = parseInt(study_app.dataset.totalPages, 10)
    let currentPage = parseInt(study_app.dataset.currentPage, 10)
    let totalCards = parseInt(study_app.dataset.totalCards, 10)
    new Vue({
      el: study_app,
      render: createElement => {
        return createElement(StudyApp,
          {
            props: {
              'initial-study': study,
              'initial-cards': cards,
              'initial-add-new': addNew,
              'initial-total-pages': totalPages,
              'initial-current-page': currentPage,
              'initial-total-cards': totalCards
            }
          }
        )
      }
    })
  }

  let card_editor = document.getElementById('card-editor-app')
  if (card_editor) {
    let card = JSON.parse(card_editor.dataset.card)
    const app = new Vue({
      el: card_editor,
      render: createElement => {
        return createElement(CardEditor,
          {
            props: {
              'initial-card': card
            }
          }
        )
      }
    })
  }

  let project_menus = document.querySelectorAll('.projects-menu-app')
  if (project_menus) {
    project_menus.forEach( menu => {
      let projects = JSON.parse(menu.dataset.projects) || []
      let endpoint = menu.dataset.endpoint || ''
      let title = menu.dataset.title || ''
      const app = new Vue({
        el: menu,
        render: createElement => {
          return createElement(ProjectMenu,
            {
              props: {
                'projects': projects,
                'endpoint': endpoint,
                'title': title
              }
            }
          )
        }
      })
    })
  }
})
