<template>
  <div>
    <div class="field is-grouped">
      <div class="control">
        <button
          class="button is-primary fileinput-button"
          :disabled="isSaving">
          <span class="icon">
            <i class="fa fa-plus"></i>
          </span>
          <span>Add Files</span>
        </button>
        <button
          class="button is-secondary"
          @click="onSubmit"
          :class="{ 'is-loading': isSaving }"
          :disabled="isSaving || !filesAdded">
          <span class="icon">
            <i class="fa fa-upload"></i>
          </span>
          <span>Start Upload</span>
        </button>
      </div>
    </div>
    <div class="field sb-form-field--vue-dropzone">
      <vue-dropzone
        ref="dropzone"
        id="dropzone"
        :options="dropzoneOptions"
        v-on:vdropzone-error="onFileError"
        v-on:vdropzone-file-added="onFileAdded"
        v-on:vdropzone-removed-file="onFileRemoved">
      </vue-dropzone>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue/dist/vue.esm.js'
import { DirectUpload } from "@rails/activestorage"
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import '../styles/dropzone_overwrite.css'
import { FILE_PROJECT_ENDPOINT } from '../consts'
import { getCSRFToken } from '../utils/railsHelpers'
import SbError from '../SbError'

const DIRECT_UPLOAD_ENDPOINT = '/rails/active_storage/direct_uploads'
const MODULE_ID = "FileProjectUploader"

const PREVIEW_TEMPLATE = `
<div class="level">
  <div class="level-left">
    <div class="level-item">
      <figure class="sb-figure image is-64x64">
        <img data-dz-thumbnail />
      </figure>
    </div>
    <div class="level-item">
      <div>
        <p class="name" data-dz-name></p>
        <p class="error has-text-danger" data-dz-errormessage></p>
      </div>
    </div>
  </div>

  <div class="level-right">
    <div class="level-item">
      <p class="size" data-dz-size></p>
    </div>
    <div class="level-item">
      <progress class="progress is-primary is-small" value="0" max="100">
    </div>
    <div class="level-item">
      <button data-dz-remove class="btn btn-danger delete">
        <span>Remove</span>
      </button>
      <span class="icon has-text-success success">
        <i class="fa fa-check"></i>
      </span>
      <span class="icon has-text-danger error">
        <i class="fa fa-exclamation"></i>
      </span>
    </div>
  </div>
</div>
`

// alias states from Dropzone lib
const Dropzone = {
  UPLOADING: 'uploading',
  ERROR: 'error',
  SUCCESS: 'success'
}

class Uploader {
  constructor(file, project) {
    this.file = file
    this.project = project
    this.direct_upload = new DirectUpload(file, DIRECT_UPLOAD_ENDPOINT, this)
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.direct_upload.create((error, blob) => {
        if (error) {
          reject(error)
        }
        else {
          return axios
            .put(`${FILE_PROJECT_ENDPOINT}/${this.project.id}/attach`,
              { attachments: [ blob.signed_id ] },
              {
                headers: {
                  'X-CSRF-Token': getCSRFToken(),
                  'Accept': 'application/json'
                }
              }
            )
            .then(async result => {
              resolve()
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => {
      this.directUploadDidProgress(event)
    })
  }

  directUploadDidProgress(event) {
    let progress = (event.loaded / event.total) * 100
    this.file.previewElement.getElementsByClassName('progress')[0].value = progress
  }
}

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    project:Object
  },
  data: function () {
    return {
      isSaving: false,
      isError: false,
      errorCount: 0,
      isCompleted: false,
      filesAdded: false,
      dropzoneOptions: {
          url: `${FILE_PROJECT_ENDPOINT}/${this.project.id}/attach`,
          acceptedFiles: 'image/*, .lif, .nd2, .oif, .mp4',
          uploadMultiple: true,
          autoProcessQueue: false,
          previewTemplate: this.template(),
          parallelUploads: 20,
          clickable: ".fileinput-button"
      }
    }
  },
  mounted: function() {
    const dropzoneObj = this.$refs.dropzone.dropzone
    const _enqueueThumbnail = dropzoneObj._enqueueThumbnail
    // overwrite vue2dropzone/dropzone.js method
    // -- don't create thumbs for specified image types
    dropzoneObj._enqueueThumbnail = function(e) {
      const noThumbTypes = ['image/tiff', 'image/heic', 'image/heif']
      if(!noThumbTypes.find(type => { return type === e.type})) {
        _enqueueThumbnail.call(dropzoneObj, e)
      }
    }
  },
  methods: {
    template() {
      return PREVIEW_TEMPLATE
    },
    onSubmit() {
      this.isSaving = true
      this.isError = false
      this.isCompleted = false
      this.errorCount = 0
      this.$emit('upload-start')
      let files = this.$refs.dropzone.getQueuedFiles()
      var queue_size = files.length
      files.forEach(file => {
        let u = new Uploader(file, this.project)
        file.status = Dropzone.UPLOADING
        u.upload().then(
          result => {
            file.status = Dropzone.SUCCESS
            file.previewElement.classList.add('dz-success', 'dz-complete')
            for (let node of file.previewElement.querySelectorAll('.dz-remove')) {
              node.style.display = "none"
            }
          },
          error => {
            file.status = Dropzone.ERROR
            for (let node of file.previewElement.querySelectorAll('[data-dz-errormessage]')) {
              node.textContent = 'Upload Error'
            }
            file.previewElement.classList.add('dz-error', 'dz-complete')
            new SbError(SbError.REQUEST_FAILED, error, MODULE_ID, 'onSubmit')
            errorCount = errorCount + 1
          }
        )
        .finally(() => {
          queue_size = queue_size - 1
          if (queue_size === 0) {
            this.onComplete()
          }
        })
      })
    },
    onFileAdded() {
      this.filesAdded = this.$refs.dropzone.getAddedFiles().length > 0
      this.$emit('files-added')
    },
    onFileRemoved() {
      this.filesAdded = this.$refs.dropzone.getAcceptedFiles().length > 0
      this.$emit('files-added')
    },
    onFileError(file, message, xhr) {
      this.isError = true
      if (!file.accepted) {
        new SbError(SbError.INVALID_MIME_TYPE, file.type, MODULE_ID)
      }
    },
    onComplete(files, response) {
      this.isSaving = false
      this.isCompleted = true
      this.onFileAdded()
      this.$emit('upload-complete')
    },
    removeAllFiles() {
      this.$refs.dropzone.removeAllFiles()
    },
    dropzoneCreateThumbnailOverride(file, width, height, resizeMethod, fixOrientation, callback) {
      const noThumbTypes = ['image/tiff', 'image/heic']
      if(!noThumbTypes.find(type => { return type === file.type})) {
        return this.dropzoneCreateThumbnail(file, width, height, resizeMethod, fixOrientation, callback)
      }
    }
  }
}
</script>

<style scoped>
  .vue-dropzone {
    border: 2px solid #149af7;
    color: #07193f;
    background-color: #647185;
    border-radius: 0;
  }
  .help {
    font-size: 1em;
  }
</style>
