<template>
  <div class="study">
    <error-modal
      v-if="error"
      :error="error"
      v-on:error-modal-close="error = null"
    >
    </error-modal>
    <card-new-modal
      v-if="isAddNewState"
      :studyId="study.id"
      v-on:card-create-success="onCardCreateSuccess"
      v-on:card-create-error="onCardCreateError"
      v-on:card-create-cancel="onModalCancel"
    >
    </card-new-modal>
    <section class="sb-section hero">
      <div class="level">
        <div class="level-left">
          <div class="control">
            <span class="select">
              <select v-model="filter" @change="onFilterChange">
                <option value="0">Active Cards ({{ activeCards.length }})</option>
                <option value="1">Archived Cards ({{ archivedCards.length }})</option>
              </select>
            </span>
          </div>
        </div>
        <div class="level-right">
          <button class="button is-secondary is-add is-pulled-right" @click="isAddNewState = true">
            <span class="icon is-small">
              <i class="fa fa-plus"></i>
            </span>
            New Card
          </button>
        </div>
      </div>
    </section>
    <section class="section sb-section">
      <table class="table is-fullwidth is-striped sb-table sb-table--listing">
        <thead>
          <tr>
            <th><a href="#">Card Name</a></th>
            <th>Date Created</th>
            <th></th>
          </tr>
        </thead>
        <tfoot v-if="totalPages > 1">
          <tr>
            <th colspan="3">
              <div class="sb-pagination">
                {{ currentPage }} of {{ totalPages }}
                <button @click="onPreviousPage" class="button" :class="{disabled: currentPage === 1}">&lt;</button>
                <span>|</span>
                <button @click="onNextPage" class="button" :class="{disabled: currentPage === totalPages}">&gt;</button>
              </div>
            </th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-if="isFilteredActive">
            <td colspan="3">
              <a @click="isAddNewState = true">Click here to begin creating new cards for this study.</a>
            </td>
          </tr>
          <tr v-else-if="isFilteredArchived">
            <td colspan="3">
              <p>There are no archived cards for this study.</p>
            </td>
          </tr>
          <tr v-else v-for="card in filteredCards" @click="onCardClick(card)">
            <td>{{ card.name }}</td>
            <td>{{ new Date(card.created_at).toLocaleString() }}</td>
            <td>
              <div class="buttons is-pulled-right">
                <a v-if="!card.is_archived" href="#" @click.stop.prevent="onDuplicateCard(card)">Duplicate</a>
                <a :href="getCardEditEndpoint(card)">Edit</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section class="section sb-section sb-section--warning">
      <div class="sb-section--title-bar">
        <h2 class="title is-4">Delete Study</h2>
      </div>
      <div class="sb-section--content">
        <p>Deleting your study and is irreversible.</p>
        <button class="button is-danger" @click="onDelete">Delete Study</button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import CardNewModal from './card_new_modal'
import ErrorModal from './error_modal'
import { STUDY_ENDPOINT, CARD_ENDPOINT } from '../consts.js'
import SbError from '../SbError'
import { getCSRFToken } from '../utils/railsHelpers'

const MODULE_ID = "Studies"
const CARDS_PER_PAGE = 25

export default {
  components: { CardNewModal, ErrorModal },
  props: {
    initialStudy: Object,
    initialCards: Array,
    initialAddNew: Boolean
  },
  data: function() {
    return {
      isAddNewState: this.initialAddNew,
      study: this.initialStudy,
      cards: this.initialCards || [],
      currentPage: 1,
      error: null,
      filter: 0
    }
  },
  computed: {
    studyEndpoint() {
      return `${STUDY_ENDPOINT}/${this.study.id}`
    },
    activeCards() {
      return this.cards.filter(function(c) {
        return !c.is_archived
      })
    },
    archivedCards() {
      return this.cards.filter(function(c) {
        return c.is_archived
      })
    },
    filteredCards() {
      let cardset = this.filter == 0 ? this.activeCards : this.archivedCards
      return cardset.slice(this.currentPage - 1 * CARDS_PER_PAGE, this.currentPage * CARDS_PER_PAGE)
    },
    totalPages() {
      let cardset = this.filter == 0 ? this.activeCards : this.archivedCards
      return Math.ceil(cardset.length / CARDS_PER_PAGE)
    },
    isFilteredActive () {
      return Number(this.filter) === 0 && !this.activeCards.length
    },
    isFilteredArchived () {
      return Number(this.filter) === 1 && !this.archivedCards.length
    }
  },
  mounted: function() {
    const urlParams = new URLSearchParams(window.location.search)
    const filterParam = urlParams.get('filter')
    if(filterParam) {
      this.filter = filterParam
    }
  },
  methods: {
    getCardEndpoint (card) {
      return `${CARD_ENDPOINT}/${card.id}/`
    },
    getCardEditEndpoint(card) {
      return `${CARD_ENDPOINT}/${card.id}/edit`
    },
    onCardCreateSuccess(card) {
      window.location.assign(`${CARD_ENDPOINT}/${card.id}/edit`)
    },
    onCardCreateError(error) {
      this.isAddNewState = false
      // error already logged by modal component
      this.error = new SbError(SbError.REQUEST_FAILED, 'Error creating new Study', MODULE_ID, 'onCardCreateError', false)
    },
    onFilterChange() {
      this.currentPage = 1
      if (window.history.pushState) {
        const newURL = new URL(window.location.href)
        newURL.search = `?filter=${this.filter}`
        window.history.pushState({ path: newURL.href }, '', newURL.href)
      }
    },
    onModalCancel() {
      this.isAddNewState = false
    },
    onNextPage() {
      if (this.currentPage !== this.totalPages) {
        this.currentPage = this.currentPage + 1
      }
    },
    onPreviousPage() {
      if (this.currentPage !== 1) {
        this.currentPage = this.currentPage - 1
      }
    },
    onCardClick(card) {
      let url = this.getCardEditEndpoint(card)
      window.location = url
    },
    onDuplicateCard(card) {
      this.isSaving = true
      let url = this.getCardEndpoint(card)
      return axios
        .post(`${url}duplicate`, card)
        .then(async result => {
          window.location = this.getCardEditEndpoint(result.data)
        })
        .catch(error => {
          this.error = new SbError(SbError.REQUEST_FAILED, 'Error duplicating your card', MODULE_ID, 'onDuplicateCard')
        })
        .finally(()=> {
          this.isSaving = false
        })
    },
    deleteStudy () {
      this.isSaving = true
      return axios
        .delete(`${this.studyEndpoint}`)
        .then(async result => {
          window.location.href = `${STUDY_ENDPOINT}`
        })
        .catch(error => {
          this.error = new SbError(SbError.REQUEST_FAILED, 'Error deleting your Study', MODULE_ID, 'deleteStudy')
        })
    },
    onDelete () {
      if (window.confirm("Do you really want to delete this Study?")) {
        this.deleteStudy()
      }
    }
  }
}
</script>

<style scoped>
</style>
