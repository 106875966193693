<template>
  <div class="file-project">
    <error-modal
      v-if="error"
      :error="error"
      v-on:error-modal-close="error = null"
    >
    </error-modal>
    <file-project-new-modal
      v-if="isAddNewState"
      v-on:project-create-success="onProjectCreateSuccess"
      v-on:project-create-error="onProjectCreateError"
      v-on:project-create-cancel="onModalCancel"
    >
    </file-project-new-modal>
    <section class="sb-section hero">
      <div class="level">
        <div class="level-left">
          <div>
            <h1 class="title sb-title">File<span>Projects</span></h1>
            <p>Select or create a new project to get started.</p>
          </div>
        </div>
        <div class="level-right">
          <button class="button is-add is-secondary" @click="isAddNewState = true">
            <span class="icon is-small">
              <i class="fa fa-plus"></i>
            </span>
            New FileProject
        </button>
        </div>
      </div>
    </section>
    <div class="container">
      <div v-if="!projects || !projects.length">
        <button class="button is-primary" @click="isAddNewState = true">Create a New Files Project</button>
      </div>
      <table v-else class="table is-fullwidth is-striped sb-table sb-table--listing">
        <thead>
          <tr>
            <th><a href="#">Project Name</a></th>
            <th>Date Created</th>
            <th></th>
          </tr>
        </thead>
        <tfoot v-if="totalPages > 1">
          <tr>
            <th colspan="3">
              <div class="sb-pagination">
                {{ currentPage }} of {{ totalPages }}
                <a href="#" @click="onPreviousPage" :class="{disabled: currentPage === 1}">&lt;</a>
                <span>|</span>
                <a href="#" @click="onNextPage" :class="{disabled: currentPage === totalPages}">&gt;</a>
              </div>
            </th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-for="p in projects" @click="onProjectClick(p)">
            <td>{{ p.name }}</td>
            <td>{{ new Date(p.created_at).toLocaleString() }}</td>
            <td>
              <div class="buttons is-pulled-right">
                <a :href="getProjectEndpoint(p)">View</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FileProjectNewModal from './file_project_new_modal'
import ErrorModal from './error_modal'
import { FILE_PROJECT_ENDPOINT } from '../consts.js'
import SbError from '../SbError'

const MODULE_ID = "FileProjects"

export default {
  components: { FileProjectNewModal, ErrorModal },
  props: {
    initialProjects: Array,
    initialAddNew: Boolean,
    initialTotalPages: Number,
    initialCurrentPage: Number
  },
  data: function() {
    return {
      isAddNewState: this.initialAddNew,
      projects: this.initialProjects,
      totalPages: this.initialTotalPages,
      currentPage: this.initialCurrentPage || 1,
      error: null
    }
  },
  methods: {
    getProjectEndpoint(project) {
      return `${FILE_PROJECT_ENDPOINT}/${project.id}`
    },
    getProjectEditEndpoint(project) {
      return `${FILE_PROJECT_ENDPOINT}/${project.id}/edit`
    },
    onProjectCreateSuccess(project) {
      window.location.assign(`${FILE_PROJECT_ENDPOINT}/${project.id}/upload`)
    },
    onProjectCreateError(error) {
      this.isAddNewState = false
      // error already logged by modal component
      this.error = new SbError(SbError.REQUEST_FAILED, 'Error creating new File Project', MODULE_ID, 'onProjectCreateError', false)
    },
    onModalCancel() {
      this.isAddNewState = false
    },
    onNextPage() {
      if (this.currentPage !== this.totalPages) {
        window.location.assign(`${FILE_PROJECT_ENDPOINT}?page=${this.currentPage + 1}`)
      }
    },
    onPreviousPage() {
      if (this.currentPage !== 1) {
        window.location.assign(`${FILE_PROJECT_ENDPOINT}?page=${this.currentPage - 1}`)
      }
    },
    onProjectClick (project) {
      let url = this.getProjectEndpoint(project)
      window.location = url
    }
  }
}
</script>

<style scoped>
</style>
