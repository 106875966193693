<template>
  <div class="project-menu">
    <div class="project-menu-head">
      <p class="project-menu-title" v-html="title"></p>
      <button
        class="button is-secondary sb-button"
        @click="onAddProject">
        <span class="icon is-medium">
          <i class="fa fa-plus"></i>
        </span>
      </button>
    </div>
    <div class="project-menu-list">
      <p class="project-menu-label">{{ this.getMenuLabel }}</p>
      <ul>
        <li v-for="project in getCurrentPageProjects">
          <a :href="endpoint + '/' + project.id">{{ project.name }}</a>
        </li>
      </ul>
    </div>
    <div class="project-menu-footer">
      <div class="project-menu-footer-link">
        <a :href="endpoint">View All</a>
      </div>
      <div v-if="projects.length > projectsPerPage" class="project-menu-footer-nav">
        <div class="sb-pagination">
          {{ currentPage }} of {{ totalPages }}
          <a :class="{disabled: currentPage === 1}" @click="onPrevious">&lt;</a>
          <span>|</span>
          <a :class="{disabled: currentPage === totalPages}" @click="onNext">&gt;</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from './file_project_uploader'

const PROJECTS_PER_PAGE = 5

export default {
  components: { FileUploader },
  props: {
    projects:Array,
    endpoint:String,
    title:String
  },
  data: function () {
    return {
      currentPage: 1,
      projectsPerPage: PROJECTS_PER_PAGE,
      totalPages: Math.round(this.projects.length / PROJECTS_PER_PAGE)
    }
  },
  computed: {
    getMenuLabel() {
      var label = "project"
      var total_projects = this.projects.length
      if (total_projects === 0 || total_projects > 1) {
        label = "projects"
      }
      return `${total_projects} ${label}`
    },
    getProjectEndpoint(project) {
      return ``
    },
    getCurrentPageProjects() {
      let start = PROJECTS_PER_PAGE * (this.currentPage - 1)
      let end = start + (PROJECTS_PER_PAGE - 1)
      return this.projects.slice(start, end)
    }
  },
  methods: {
    onProjectClick(project) {
      window.location = `${this.endpoint}/${project.id}`
    },
    onAddProject() {
      window.location = `${this.endpoint}?wizard=1`
    },
    onNext() {
      if (this.currentPage !== this.totalPages) {
        this.currentPage = this.currentPage + 1
      }
    },
    onPrevious() {
      if (this.currentPage !== 1) {
        this.currentPage = this.currentPage - 1
      }
    }
  }
}
</script>

<style scoped>
  .project-menu {
    background-color: #394963;
  }
  .project-menu-head {
    position: relative;
    padding: 10px 20px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
  }
  .project-menu-head .sb-button {
    position: absolute;
    top: 6px;
    right: 10px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
  }
  .project-menu-list {
    padding: 20px;
  }
  .project-menu-list ul {
    margin:20px 0;
  }
  .project-menu-list li {
    width: 100%;
    margin-bottom: 20px;
    background-color: #414f67;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
  }
  .project-menu-list li a {
    display: block;
    position: relative;
    padding: 0 20px;
    height: 44px;
    line-height: 2.75;
    color: #cdcdcd;
  }
  .project-menu-list li a::after {
    position: absolute;
    right: 20px;
    content: ">";
    color: #149af7;
  }
  .project-menu-list li a:hover {
    background-color: #4d5e7a;
    color: #ffffff;
  }
  .project-menu-list div {
    display: inline-block;
  }
  .project-menu-list div:last-child {
    float: right;
  }
  .project-menu-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 15px 20px 20px;
  }
  .project-menu-footer .project-menu-footer-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .project-menu-footer .project-menu-footer-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .project-menu-footer .project-menu-footer-nav a {
    padding: 0 5px;
  }
</style>
