<template>
  <div>
    <div class="field columns" v-for="(label, index) in labels" :class="{ isStriped: isStriped(index) }">
      <div class="column is-3">
        <input class="input" type="text" placeholder="Criterion" v-model="label.name" :key="index" :disabled="isSaving">
      </div>
      <div class="column is-5">
        <div v-if="label.is_blinded" class="control">
          <p class="blinded"><span>********</span> This value is blinded. <span>********</span></p>
        </div>
        <div v-else class="control">
          <textarea class="textarea" placeholder="Value" v-model="label.value" :disabled="isSaving"></textarea>
        </div>
      </div>
      <div class="column is-2">
        <div class="checkbox-container">
          <input :id="'checkboxPublic' + index" type="checkbox" v-model="label.is_public" name="" value="" :disabled="isSaving">
          <label :for="'checkboxPublic' + index"><span>Public</span></label>
        </div>
        <div class="checkbox-container">
          <input :id="'checkboxBlinded' + index" type="checkbox" v-model="label.is_blinded" name="" value="" :disabled="isSaving">
          <label :for="'checkboxBlinded' + index"><span>Blinded</span></label>
        </div>
      </div>
      <div class="column is-2 actions-container">
        <div class="control">
          <button class="is-duplicate is-primary button" data-tooltip="Duplicate Label" @click="onDuplicateLabel(label)">
            <span class="icon is-medium">
              <i class="fa fa-clone"></i>
            </span>
          </button>
          <button class="is-delete button" data-tooltip="Delete Label" @click="onRemoveLabel(label)">
            <span class="icon is-medium">
              <i class="fa fa-trash-o"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="field btn-container add-btn-container">
      <div class="control">
        <button class="button is-add is-secondary" @click="onAddLabel">
          <span class="icon is-small">
            <i class="fa fa-plus"></i>
          </span>
           New Label
        </button>
      </div>
    </div>
    <div class="field is-grouped btn-container">
      <div class="control">
        <button
          class="button is-primary"
          @click="onSubmit"
          :class="{ 'is-loading': isSaving }"
          :disabled="isSaving">
            Save Labels
        </button>
      </div>
    </div>
    <div v-if="notification" :class="{ 'is-danger': isError }">
      {{ notification }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue/dist/vue.esm.js'
import { CARD_ENDPOINT } from '../consts'
import SbError from '../SbError'

const MODULE_ID = "CardLabeler"

const getEmptyLabel = () => {
  return { name: '', value: '', is_public: true, is_blinded: false }
}

export default {
  props: {
    card: Object
  },
  data: function () {
    return {
      isSaving: false,
      isError: false,
      labels: this.card.label_data || [ getEmptyLabel() ],
      notification: ''
    }
  },
  methods: {
    onSubmit() {
      this.saveLabels()
    },
    saveLabels() {
      this.isSaving = true
      return axios
        .put(`${CARD_ENDPOINT}/${this.card.id}`, {
          label_data: this.labels.filter( l => {
            return l.name
          })
        })
        .then(async result => {
          this.labels = result.data.label_data
          this.$emit('labels-save-success')
        })
        .catch(error => {
          this.$emit('labels-save-error')
          new SbError(SbError.REQUEST_FAILED, error, MODULE_ID, 'saveLabels')
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    onAddLabel() {
      this.labels.push(getEmptyLabel())
      this.$emit('labels-modified')
    },
    onRemoveLabel(label) {
      if (window.confirm("Do you really want to delete this label?")) {
        var index = this.labels.indexOf(label)
        this.labels.splice(index, 1)
        this.$emit('labels-modified')
      }
    },
    onDuplicateLabel(label) {
      var index = this.labels.indexOf(label)
      let new_index = index + 1
      let duplicate = {
        name: label.name + ' (copy)',
        value: label.value,
        is_public: label.is_public,
        is_blinded: label.is_blinded
      }
      this.labels.splice(new_index, 0, duplicate)
      this.$emit('labels-modified')
    },
    isStriped(index) {
      return Math.abs(index % 2) === 1
    },
    getTotalCards () {
      return this.labels.length
    }
  }
}
</script>

<style scoped>
</style>
